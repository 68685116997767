import { useToast } from "@chakra-ui/react";
import moment from "moment";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { useTetherToast } from "../../../components/toast/useTetherToast";
import { useAuth } from "../../auth/useAuth";
import { useAggregatedQueries } from "../../hooks/useAggregatedQueries";
import ReportService, {
  ReportEntityNames,
  ReportOptions,
  ReportResult,
  ReportType,
} from "../../reports/ReportService";
import { TetherTickPropertySnapshot } from "../../utils/tetherTick";
import { api, NotFoundError } from "../api";
import { Circuit } from "../types/Circuit";
import { CircuitNode, CircuitNodeType, CircuitNodeUsage, CircuitNodeWithUsage } from "../types/CircuitNode";
import { EnergyGoal } from "../types/EnergyGoal";
import { FloorArea } from "../types/FloorArea";
import { Organisation } from "../types/Organisation";
import { OrganisationTag } from "../types/OrganisationTag";
import { Outside } from "../types/Outside";
import { Property } from "../types/Property";
import { PropertyDetail } from "../types/PropertyDetail";
import { PropertyInfo } from "../types/PropertyInfo";
import { Room } from "../types/Room";
import { TimestampRange } from "../types/TimestampRange";
import { DateRangeResolution } from "../../../components/property/DateRangeControls";
import { ReadingResolution } from "../readings/hooks";
import { POWER_READING } from "../../../components/property-graph/GraphUtils";
import { UserQueryKeys } from "../users/hooks";
import { OutsideWithReading } from "../types/OutsideWithReading";
import { getQueryParamAsString } from "../../utils/routerUtils";
import { buildQueryParam } from "../../utils/buildQueryParams";
import { DeviceType } from "../types/GenericDevice";

export type MutationProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess?: (data?: any) => void;
  onError?: (error: unknown) => void;
};

export enum QueryKeys {
  PROPERTY = "property",
  ROOMS = "room",
  OUTSIDE = "outside",
  CIRCUIT = "circuit",
  PALACE_ID = "palace-id",
  FLOOR_AREA = "floor-area",
  TAGS = "property-tags",
  PROPERTY_READINGS = "property-readings",
  PROPERTY_POWER = "property-power",
  PROPERTY_WATER = "property-water",
  PROPERTY_PULSE_READINGS = "property-pulse-readings",
  ROOM_LOCATION_READINGS = "room-location-readings",
  ROOM_LOCATION_READINGS_INSIGHTS = "room-location-insights",
  ROOM_READINGS = "room-readings",
  CIRCUIT_READINGS = "circuit-readings",
  OUTSIDE_READINGS = "outside-readings",
  CIRCUITNODES = "circuit-nodes",
  ORGANISATION_TAGS = "organisation-tags",
  OCCUPANCY_READINGS = "occupancy-readings",
  OUTSIDE_WATER = "outside-water",
  OUTSIDE_PULSE_METER = "outside-pulse-meter",
  PROPERTY_REPORT = "property-report",
  ORGANISATION = "organisation",
  CUSTOMER = "customer",
  DEVICELIST = "device-list",
  INSIGHT_HIGHLIGHTS = "insight-highlights",
  INSIGHT_HIGHLIGHTS_DETAIL = "insight-highlights-detail",
  INSIGHT_HIGHLIGHTS_CHART = "insight-highlights-chart",
  DISABLE_INISGHT_HIGHLIGHT = "disable-insight-highlight",
  PROPERTY_INSIGHT_READINGS = "property-insights-readings",
}

export const enum LocationType {
  RoomLocation = "ROOMLOCATION",
  Outside = "OUTSIDE",
  Circuit = "CIRCUIT",
}

export const useGetProperties = (options?: UseQueryOptions<Property[]>) =>
  useQuery<Property[]>([QueryKeys.PROPERTY, { allProperties: true }], () => api.get("property/v2/property"), {
    ...options,
    staleTime: Infinity,
  });

export const useGetProperty = (id: string | undefined, options?: UseQueryOptions<Property>) => {
  const { data: property, ...rest } = useQuery<Property>(
    [QueryKeys.PROPERTY, id],
    () => api.get(`property/v2/property/${id}`),
    {
      enabled: Boolean(id),
      staleTime: Infinity,
      ...options,
    }
  );
  return { property, ...rest };
};

export const useGetFloorArea = (id: string | undefined) => {
  const { data, ...rest } = useQuery<FloorArea>(
    [QueryKeys.FLOOR_AREA, id],
    () => api.get(`v2/installation/${id}/detail/floorArea`),
    {
      enabled: Boolean(id),
      staleTime: Infinity,
      retry: false,
    }
  );
  return { floorArea: data?.value, ...rest };
};

export const usePropertyDeviceCount = (propertyId: string) => {
  const { data = [], isLoading } = useGetOrganisationPropertyInfo();

  const property = data.find((p) => {
    return p.id === propertyId;
  });

  const deviceCounts = property?.deviceCounts;

  return {
    deviceCounts,
    isLoading,
  };
};

// return {
//   isLoading,
//   deviceCount: {
//     propertyInfo!.
//   }
// }

export const useGetPropertyDetail = (id: string, options?: UseQueryOptions<PropertyDetail, Error>) => {
  const { data: propertyInfo, ...rest } = useQuery<PropertyDetail, Error>(
    [QueryKeys.PROPERTY, id, { propertyInfo: true }],
    () => api.get(`experience/v1/property/property-info/${id}`),
    {
      enabled: Boolean(id),
      staleTime: Infinity,
      ...options,
    }
  );
  return { propertyInfo, ...rest };
};

interface PropertyDetailResult {
  id: string;
  propertyInfo: PropertyDetail;
}

export const useGetPropertyDetailForMultipleProperties = (
  ids: string[],
  options?: UseQueryOptions<PropertyDetail, Error, PropertyDetailResult>
) => {
  const { data: propertyInfos, ...rest } = useAggregatedQueries<PropertyDetail, Error, PropertyDetailResult>(
    ids.map((id) => {
      return {
        queryKey: [QueryKeys.PROPERTY, id, { propertyInfo: true }],
        queryFn: () => api.get(`experience/v1/property/property-info/${id}`),
        staleTime: Infinity,
        enabled: Boolean(id),
        select: (propertyInfo: any) => ({
          id,
          propertyInfo: propertyInfo as PropertyDetail,
        }),
        ...options,
      };
    })
  );

  return { propertyInfos, ...rest };
};

export const useGetPropertyTetherTickSnapshots = (id: string | undefined) => {
  const isStaging = window.location.hostname.includes("staging");

  const apiRoot = isStaging
    ? "https://complianceapi.staging.tetherhq.com"
    : "https://complianceapi.tetherhq.com"; //

  const { data: snapshots, ...rest } = useQuery<TetherTickPropertySnapshot[]>(
    [QueryKeys.PROPERTY, id, { tetherTick: true }],
    () => api.get(apiRoot + `/snapshot/${id}`),
    {
      enabled: Boolean(id),
      staleTime: Infinity,
    }
  );
  return { snapshots, ...rest };
};

export const useGetRooms = (propertyId: string) => {
  const { data: rooms, ...rest } = useQuery<Room[]>(
    [QueryKeys.ROOMS, propertyId],
    () => api.get(`property/v2/room?propertyId=${propertyId}`),
    {
      enabled: Boolean(propertyId),
      staleTime: Infinity,
    }
  );
  return { rooms, ...rest };
};

export interface DeleteRoomInput {
  roomId: string;
  propertyId: string;
}

export const useDeleteRoomMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { errorToast, successToast } = useTetherToast();

  const handleDeleteRoom = async ({ input }: { input: DeleteRoomInput }) => {
    try {
      const locations = await api.get("property/v2/roomlocation", { roomId: input.roomId });
      for (const location of locations) {
        await api.del(`property/v2/roomlocation/${location.id}`);
      }
    } catch (e) {
      queryClient.invalidateQueries([QueryKeys.PROPERTY, input.propertyId]);
      queryClient.invalidateQueries([QueryKeys.ROOMS, input.propertyId]);
      throw e;
    }
    return api.del(`property/v2/room/${input.roomId}`);
  };

  return useMutation(handleDeleteRoom, {
    onError: (e: Error) => {
      errorToast({
        title: "Unable to delete room",
        description: e.message,
      });
    },
    onSuccess: (data, { input }) => {
      queryClient.invalidateQueries([QueryKeys.PROPERTY, input.propertyId]);
      queryClient.invalidateQueries([QueryKeys.ROOMS, input.propertyId]);
      if (onSuccess) {
        onSuccess();
      }
      successToast({
        title: "Successfully deleted room",
      });
    },
  });
};

export interface DeleteLocationInput {
  locationId: string;
  propertyId: string;
  locationType: LocationType;
}

export const useDeleteLocationMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { errorToast, successToast } = useTetherToast();

  const handleDeleteLocation = async ({ input }: { input: DeleteLocationInput }) => {
    const { locationId, locationType } = input;
    switch (locationType) {
      case LocationType.RoomLocation:
        return api.del(`property/v2/roomlocation/${locationId}`);
      case LocationType.Outside:
        return api.del(`property/v2/outside/${locationId}`);
      case LocationType.Circuit:
        return api.del(`property/v2/circuit/${locationId}`);
      default:
        throw new Error(`Unknown Location Type: ${locationType}`);
    }
  };

  return useMutation(handleDeleteLocation, {
    onError: (e: Error) => {
      errorToast({
        title: "Unable to delete location",
        description: e.message,
      });
    },
    onSuccess: (data, { input }) => {
      queryClient.invalidateQueries([QueryKeys.PROPERTY, input.propertyId]);
      queryClient.invalidateQueries([QueryKeys.ROOMS, input.propertyId]);
      queryClient.invalidateQueries([QueryKeys.CIRCUITNODES, input.propertyId]);
      queryClient.invalidateQueries([QueryKeys.CIRCUIT, input.propertyId]);
      if (onSuccess) {
        onSuccess();
      }
      successToast({
        title: "Successfully deleted location",
      });
    },
  });
};

export const useGetRoom = (roomId: string, options?: UseQueryOptions<Room, Error>) => {
  const { data: room, ...rest } = useQuery<Room, Error>(
    [QueryKeys.ROOMS, roomId],
    () => api.get(`property/v2/room/${roomId}`),
    {
      enabled: Boolean(roomId),
      staleTime: Infinity,
      ...options,
    }
  );
  return { room, ...rest };
};

export const useGetOutside = (outsideId: string, options?: UseQueryOptions<OutsideWithReading, Error>) => {
  const { data: outside, ...rest } = useQuery<OutsideWithReading, Error>(
    [QueryKeys.OUTSIDE, outsideId],
    () => api.get(`property/v2/outside/${outsideId}`),
    {
      enabled: Boolean(outsideId),
      staleTime: Infinity,
      ...options,
    }
  );
  return { outside, ...rest };
};

export const useGetOutsidesForProperty = (
  propertyId: string,
  options?: UseQueryOptions<OutsideWithReading[], Error>
) => {
  const { data: outsides, ...rest } = useQuery<OutsideWithReading[], Error>(
    [QueryKeys.OUTSIDE, propertyId],
    () => api.get("property/v2/outside", { propertyId }),
    {
      enabled: Boolean(propertyId),
      staleTime: Infinity,
      ...options,
    }
  );
  return { outsides, ...rest };
};

export const useGetCircuit = (circuitId: string, options?: UseQueryOptions<Circuit, Error>) => {
  const { data: circuit, ...rest } = useQuery<Circuit, Error>(
    [QueryKeys.CIRCUIT, circuitId],
    () => api.get(`property/v2/circuit/${circuitId}`),
    {
      enabled: Boolean(circuitId),
      staleTime: Infinity,
      ...options,
    }
  );
  return { circuit, ...rest };
};

export const useGetCircuitNode = (
  circuitNodeId: string | undefined,
  options?: UseQueryOptions<CircuitNode, Error>
) => {
  const { data: circuitNode, ...rest } = useQuery<CircuitNode, Error>(
    [QueryKeys.CIRCUITNODES, circuitNodeId],
    () => api.get(`property/v2/circuitnode/${circuitNodeId}`),
    {
      enabled: Boolean(circuitNodeId),
      staleTime: Infinity,
      ...options,
    }
  );
  return { circuitNode, ...rest };
};

export const useGetStreetViewImage = (propertyId: string) => {
  const queryClient = useQueryClient();
  const key = ["streetViewImage", propertyId];
  const { data: imageUrl, ...rest } = useQuery<{ imageUrl: string }>(
    key,
    () => api.get(`property/v2/property/${propertyId}/streetview`),
    {
      enabled: Boolean(propertyId),
      retry: false,
      staleTime: Infinity,
      onError: (error) => {
        if (error as NotFoundError) {
          queryClient.setQueryData(key, undefined);
        }
      },
    }
  );
  return { imageUrl, ...rest };
};

export interface DeletePropertyInput {
  id: string;
}

export const useDeletePropertyMutation = () => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    ({ input }: { input: DeletePropertyInput }) => api.del(`property/v2/property/${input.id}`),
    {
      onError: () => {
        toast({
          title: "Unable to delete property",
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      },
      onSuccess: (data, { input }) => {
        router.replace("/");
        queryClient.invalidateQueries([QueryKeys.PROPERTY, { orgPropertyInfo: true }]);
      },
    }
  );
};

export type CreateRoomInput = {
  type: string;
  floorIndex: number;
  name: string;
  color: string;
  propertyId: string;
  customId?: string | null;
  capacity?: number | null;
  tags?: string[];
};

export const useCreateRoomMutation = (onCreated?: () => void) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const handleCreateRoom = async ({ input }: { input: CreateRoomInput }) => {
    const room = await api.post("property/v2/room", input);

    // Hopefully temporary hack to create a room location when creating a room,
    // without this it won't be visible in the mobile app
    try {
      await api.post("property/v2/roomLocation", { ...input, roomId: room.id });
    } catch (e) {
      // Don't throw an error here as it will have created the room already and
      // things'll break in a confusing way if they try and click create again.
      // Lettuce spray this doesn't happen...
    }
  };

  return useMutation(handleCreateRoom, {
    onError: () => {
      toast({
        title: "Unable to create area",
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    },
    onSuccess: (data, { input }) => {
      toast({
        title: "Area Created",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      // Invalidate and refetch
      queryClient.invalidateQueries(QueryKeys.PROPERTY);
      queryClient.invalidateQueries([QueryKeys.ROOMS, input.propertyId]);
      if (onCreated) {
        onCreated();
      }
    },
  });
};

export const useUpdateRoomMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(({ input }: { input: Room }) => api.put(`property/v2/room/${input.id}`, input), {
    onError: () => {
      toast({
        title: "Unable to update area",
        status: "error",
        isClosable: true,
        position: "top-right",
      });
    },
    onSuccess: (data, { input }) => {
      toast({
        title: "Area Updated",
        status: "success",
        isClosable: true,
        position: "top-right",
      });
      // Invalidate and refetch
      queryClient.invalidateQueries(QueryKeys.PROPERTY);
      queryClient.invalidateQueries([QueryKeys.ROOMS, input.id]);
      if (onSuccess) {
        onSuccess();
      }
    },
  });
};

export const useUpdateLocationMutation = (type: LocationType) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    ({
      input,
    }: {
      input: { id: string; name?: string; color?: string; roomId?: string };
      propertyId: string;
      onSuccess?: () => void;
    }) => api.put(`property/v2/${type.toLowerCase()}/${input.id}`, input),
    {
      onError: () => {
        toast({
          title: "Unable to update",
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      },
      onSuccess: (data, { input, propertyId, onSuccess }) => {
        toast({
          title: "Updated.",
          status: "success",
          isClosable: true,
          position: "top-right",
        });
        // Invalidate and refetch
        queryClient.invalidateQueries([QueryKeys.PROPERTY, propertyId]);
        queryClient.invalidateQueries([QueryKeys.ROOMS, input.roomId]);
        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );
};

interface CircuitMutationInput {
  id: string;
  propertyId: string;
  name: string;
  color: string;
  phase: number | null;
}

export const useUpdateCircuitMutation = () => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    ({ input }: { input: CircuitMutationInput; propertyId: string; onSuccess?: () => void }) =>
      api.put(`property/v2/circuit/${input.id}`, input),
    {
      onError: () => {
        toast({
          title: "Unable to update",
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      },
      onSuccess: (data, { input, propertyId, onSuccess }) => {
        toast({
          title: "Updated.",
          status: "success",
          isClosable: true,
          position: "top-right",
        });
        // Invalidate and refetch
        queryClient.invalidateQueries([QueryKeys.PROPERTY, propertyId]);
        queryClient.invalidateQueries([QueryKeys.CIRCUITNODES, propertyId]);
        queryClient.invalidateQueries([QueryKeys.CIRCUIT, input.id]);
        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );
};

export const useGetOrganisationPropertyInfo = () => {
  return useQuery<PropertyInfo[]>(
    [QueryKeys.PROPERTY, { orgPropertyInfo: true }],
    () => api.get("/experience/v1/property/organisation-property-info"),
    {
      staleTime: Infinity,
      refetchInterval: 0,
      refetchOnWindowFocus: false,
    }
  );
};

export interface DeviceListObject {
  id: string;
  shortSerial: string;
  type: string;
  sku: string;
  firmwareVersion: string;
  hardwareVersion: string;
  subDeviceIndex: null | number;
  createdAt: string;
  lastSample: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sample: any;
    timestamp: number;
    deviceId: string;
  };
  signalStrengthPercentage: {
    rssi: number;
    percentage: number;
    timestamp?: string;
  };
  deviceAssignment?: {
    propertyId: string;
    roomId?: string;
  };
  gatewayStatus?: {
    lastUplinkReceivedAt: string;
    isConnected: boolean;
  };
}

interface DeviceListResponse {
  devices: DeviceListObject[];
  page: number;
  totalPages: number;
}

interface DeviceListSort {
  type?: string;
  page?: number;
  sort?: string; // 'serialNumber' | 'battery' | 'signal' | 'lastSampleTimestamp';
  desc?: boolean;
  propertyId?: string;
  status?: string;
  battery?: string;
  signalStrength?: string;
}

export const useDeviceListExperience = ({
  page,
  type,
  sort,
  desc,
  propertyId,
  status,
  battery,
  signalStrength,
}: DeviceListSort) => {
  const queryParam = buildQueryParam({ page, sort, desc, type, propertyId, status, battery, signalStrength });
  return useQuery<DeviceListResponse>(
    [QueryKeys.DEVICELIST, { queryParam }],
    () => api.get("/experience/v1/device?" + queryParam),
    {
      staleTime: 60000,
    }
  );
};

// export const useDeviceListExperienceAllPages = () => {

//   const [page, setPage] = useState(1);
//   const [hasMore, setHasMore] = useState(true);
//   const [devices, setDevices] = useState<DeviceListObject[]>([]);
//   const [isLoading, setIsLoading] = useState(false);

//   const fetchPage = async (page: number) => {
//     setIsLoading(true);
//     const queryParam = buildQueryParam({ page });
//     const response: DeviceListResponse = await api.get("/experience/v1/device?" + queryParam);
//     setIsLoading(false);
//     setDevices([...devices, ...response.devices]);
//     setHasMore(response.devices?.length > 0);
//     setPage(page + 1);
//   }

//   useEffect(() => {
//     if (hasMore) {
//       fetchPage(page);
//     }
//   }, [page, hasMore]);

//   return {
//     devices,
//     isLoading: isLoading || hasMore
//   }
// }

export interface CreatePropertyInput {
  type: string;
  customPropertyId?: string;
  address1?: string;
  address2?: string;
  addressCity?: string;
  addressCountry?: string;
  addressPostcode?: string;
  latitude?: number;
  longitude?: number;
  floorArea?: number;
  tags?: string[];
}

export const useCreatePropertyMutation = (onPropertyCreated?: (id: string) => void) => {
  const queryClient = useQueryClient();

  const createProperty = async ({ input }: { input: CreatePropertyInput }) => {
    const { floorArea, tags, ...rest } = input;

    try {
      const res = await api.post(`property/v2/property`, rest);
      const additionalPromises = [];
      if (floorArea) {
        additionalPromises.push(
          api.post(`v2/installation/${res.id}/detail/floorArea`, { type: "number", value: floorArea })
        );
      }

      if (tags) {
        tags.forEach((tag) =>
          additionalPromises.push(api.put(`property/v2/property/${res.id}/tag`, { tag }))
        );
      }

      await Promise.all(additionalPromises);

      return res;
    } catch (e) {
      console.log("Unable to update property");
    }
  };

  return useMutation(createProperty, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(QueryKeys.PROPERTY);
      queryClient.invalidateQueries(QueryKeys.TAGS);
      if (onPropertyCreated) {
        onPropertyCreated(data.id);
      }
    },
  });
};

interface PropertyMutationProps extends Property {
  tagsAdded?: string[];
  tagsRemoved?: string[];
}

export const useUpdatePropertyMutation = (onSuccess?: (id: string) => void) => {
  const queryClient = useQueryClient();
  const { errorToast } = useTetherToast();

  const updateProperty = async ({ input }: { input: PropertyMutationProps }) => {
    const { floorArea, tagsAdded, tagsRemoved, ...rest } = input;
    const propertyId = rest.id;

    const res = await api.put(`property/v2/property/${propertyId}`, rest);

    const additionalPromises = [];

    additionalPromises.push(
      api.post(`v2/installation/${propertyId}/detail/floorArea`, { type: "number", value: floorArea || 0 })
    );

    if (tagsAdded) {
      tagsAdded.forEach((tag) =>
        additionalPromises.push(api.put(`property/v2/property/${propertyId}/tag`, { tag }))
      );
    }
    if (tagsRemoved) {
      tagsRemoved.forEach((tag) =>
        additionalPromises.push(api.del(`property/v2/property/${propertyId}/tag/${tag}`))
      );
    }

    await Promise.all(additionalPromises);
    return res;
  };

  return useMutation(updateProperty, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(QueryKeys.PROPERTY);
      queryClient.invalidateQueries([QueryKeys.FLOOR_AREA, data.id]);
      queryClient.invalidateQueries(QueryKeys.TAGS);
      if (onSuccess) {
        onSuccess(data.id);
      }
    },
    onError: (error: Error) => {
      errorToast({
        title: "Unable to update property",
        description: error.message,
      });
    },
  });
};

export default function useCreatePropertyReport() {
  const [loadingReportText, setLoadingReportText] = useState<string | undefined>(undefined);

  const createPropertyReport = (
    entityId: string,
    propertyReportType: ReportType,
    options?: ReportOptions
  ): Promise<ReportResult> => {
    const { date, ...otherOptions } = options || {};

    return new Promise((resolve, reject) => {
      let hasReturned = false;
      setLoadingReportText("Generating Report...");
      ReportService.getPropertyReport(entityId, propertyReportType, {
        date: date || moment().subtract(1, "month").format("MMM YYYY"),
        ...otherOptions,
      })
        .then((report: ReportResult) => {
          hasReturned = true;
          setLoadingReportText(undefined);
          resolve(report);
        })
        .catch((err) => {
          hasReturned = true;
          setLoadingReportText(undefined);
          reject(err);
        });
      setTimeout(() => {
        if (!hasReturned) {
          setLoadingReportText("Still Generating...");
        }
      }, 6000);
    });
  };

  return { loadingReportText, createPropertyReport };
}

export function useCustomPropertyReportMutation() {
  const [loadingReportText, setLoadingReportText] = useState<string | undefined>(undefined);

  const createReport = async ({
    entityId,
    reportType,
    options,
  }: {
    entityId: string;
    reportType: ReportType;
    options?: Record<string, string>;
  }): Promise<ReportResult> => {
    let hasReturned = false;
    setLoadingReportText("Generating Report...");

    const result: any = await api.post("/v2/report", {
      entityName: ReportEntityNames[reportType],
      entityId,
      type: reportType,
      parameters: options,
    });

    setTimeout(() => {
      if (!hasReturned) {
        setLoadingReportText("Still Generating...");
      }
    }, 6000);

    try {
      const reportResult = (await ReportService.pollReportResult(result.id)) as ReportResult;
      hasReturned = true;
      setLoadingReportText(undefined);
      return reportResult;
    } catch (err) {
      hasReturned = true;
      setLoadingReportText(undefined);
      throw err;
    }
  };

  const mutation = useMutation(createReport);

  return { ...mutation, loadingReportText };
}

export const useGetPropertyPalaceId = (propertyId: string) => {
  return useQuery<string>(
    [QueryKeys.PALACE_ID, { propertyId }],
    () => api.get(`/v2/installation/${propertyId}/palacepropertyid`),
    {
      enabled: Boolean(propertyId),
      retry: false, // 404 is a valid response
      staleTime: Infinity,
    }
  );
};

export type PropertyPalaceIdMap = {
  propertyId: string;
  palaceId: string;
};

export const useGetPalaceIdMap = (propertyIds: string[]) => {
  const { data } = useGetOrganisationPropertyInfo();

  const palaceIdMap: Record<string, string> = {};
  if (data) {
    data.filter((o) => o.palaceId).map((o) => (palaceIdMap[o.id] = o.palaceId!));
  }

  return {
    palaceIdMap,
  };
};

export interface RemoveDeviceInput {
  locationId: string;
  locationType: LocationType;
}

export const useRemoveDeviceMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const { errorToast, successToast } = useTetherToast();

  const handleRemoveDevice = ({ input }: { input: RemoveDeviceInput }) => {
    const { locationId, locationType } = input;

    switch (locationType) {
      case LocationType.RoomLocation:
        return api.del(`property/v2/roomlocation/${locationId}/device`);
      case LocationType.Outside:
        return api.del(`property/v2/outside/${locationId}/device`);
      case LocationType.Circuit:
        return api.del(`property/v2/circuit/${locationId}/device`);
      default:
        throw new Error(`Unknown Location Type: ${locationType}`);
    }
  };

  return useMutation(handleRemoveDevice, {
    onError: (e: Error) => {
      errorToast({
        title: "Unable to remove device",
        description: e.message,
      });
    },
    onSuccess: (data, { input }) => {
      queryClient.invalidateQueries(QueryKeys.ROOMS);
      queryClient.invalidateQueries(QueryKeys.PROPERTY);
      if (onSuccess) {
        onSuccess();
      }
      successToast({
        title: "Successfully removed device",
      });
    },
  });
};

interface TagReponse {
  tags: string[];
}

export const useGetPropertyTags = (id: string, options?: UseQueryOptions<TagReponse, Error>) => {
  const { data, ...rest } = useQuery<TagReponse, Error>(
    [QueryKeys.TAGS, id],
    () => api.get(`property/v2/property/${id}/tag`),
    {
      enabled: Boolean(id),
      staleTime: Infinity,
      ...options,
    }
  );
  return { tags: data?.tags, ...rest };
};

export const useGetPropertyTagsForMultipleProperties = (
  ids: string[],
  options?: UseQueryOptions<TagReponse, Error>
) => {
  return useAggregatedQueries(
    !ids.length
      ? []
      : ids.map((id) => {
          const curr = id;
          return {
            queryKey: [QueryKeys.TAGS, curr],
            queryFn: () => api.get(`property/v2/property/${id}/tag`),
            select: (res: any) => ({ id: curr, tags: res?.tags }),
            staleTime: 60000,
          };
        })
  );
};

export const useGetAllPropertyTags = (options?: UseQueryOptions<string[], Error>) => {
  const { data: tags, ...rest } = useQuery<string[], Error>(
    [QueryKeys.TAGS],
    () => api.get(`property/v2/propertytag`),
    {
      staleTime: Infinity,
      ...options,
    }
  );
  return { tags, ...rest };
};

export const useAddPropertyTagMutation = (onSuccess?: (tag: string) => void) => {
  const queryClient = useQueryClient();

  const updateTags = async ({ propertyId, tag }: { propertyId: string; tag: string }) => {
    try {
      const res = await api.put(`property/v2/property/${propertyId}/tag`, { tag });
      return { propertyId, ...res };
    } catch (e) {
      console.log("Unable to add tag");
    }
  };

  return useMutation(updateTags, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKeys.PROPERTY, data.propertyId]);
      queryClient.invalidateQueries(QueryKeys.TAGS);
      if (onSuccess) {
        onSuccess(data.tags);
      }
    },
  });
};

export const useRemovePropertyTagMutation = (onSuccess?: (tag: string) => void) => {
  const queryClient = useQueryClient();

  const updateTags = async ({ propertyId, tag }: { propertyId: string; tag: string }) => {
    try {
      const res = await api.del(`property/v2/property/${propertyId}/tag`, { tag });
      return { propertyId, ...res };
    } catch (e) {
      console.log("Unable to remove tag");
    }
  };

  return useMutation(updateTags, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKeys.PROPERTY, data.propertyId]);
      queryClient.invalidateQueries(QueryKeys.TAGS);
      if (onSuccess) {
        onSuccess(data.tags);
      }
    },
  });
};

export const useGetOrganisationTags = () => {
  const { user } = useAuth();
  const { data, ...rest } = useQuery<OrganisationTag[], Error>(
    [QueryKeys.ORGANISATION_TAGS, user?.currentOrganisationId],
    () => api.get(`v2/organisationTag?organisationId=${user!.currentOrganisationId}`),
    {
      enabled: Boolean(user?.currentOrganisationId),
      staleTime: Infinity,
    }
  );
  return { organisationTags: data, ...rest };
};

// Create OrganisationTag
export const useCreateOrganisationTag = () => {
  const queryClient = useQueryClient();

  const handleCreateOrganisationTag = async ({
    input,
  }: { input: Omit<OrganisationTag, "id"> } & MutationProps) => {
    return await api.post("/v2/organisationTag", { ...input });
  };

  return useMutation(handleCreateOrganisationTag, {
    onError: (error: unknown, { onError }) => {
      if (onError) onError(error);
    },
    onSuccess: (data, { input, onSuccess }) => {
      queryClient.invalidateQueries([QueryKeys.ORGANISATION_TAGS, input.organisationId]);

      if (onSuccess) onSuccess(data);
    },
  });
};

// Update OrganisationTag
export const useUpdateOrganisationTag = () => {
  const queryClient = useQueryClient();

  const handleUpdateOrganisationTag = async ({ input }: { input: OrganisationTag } & MutationProps) => {
    return await api.put(`/v2/organisationTag/${input.id}`, { ...input });
  };

  return useMutation(handleUpdateOrganisationTag, {
    onError: (error: unknown, { onError }) => {
      if (onError) onError(error);
    },
    onSuccess: (data, { input, onSuccess }) => {
      queryClient.invalidateQueries([QueryKeys.ORGANISATION_TAGS, input.organisationId]);

      if (onSuccess) onSuccess(data);
    },
  });
};

// Delete OrganisationTag
export const useDeleteOrganisationTag = () => {
  const queryClient = useQueryClient();

  const handleDeleteOrganisationTag = async ({
    input,
  }: { input: { id: string; organisationId: string } } & MutationProps) => {
    return await api.del(`/v2/organisationTag/${input.id}`);
  };

  return useMutation(handleDeleteOrganisationTag, {
    onError: (error: unknown, { onError }) => {
      if (onError) onError(error);
    },
    onSuccess: (data, { input, onSuccess }) => {
      queryClient.invalidateQueries([QueryKeys.ORGANISATION_TAGS, input.organisationId]);

      if (onSuccess) onSuccess(data);
    },
  });
};

export const useGetCircuitNodeTree = (propertyId: string | undefined) => {
  const { data, ...rest } = useQuery<CircuitNode[], Error>(
    [QueryKeys.CIRCUITNODES, propertyId],
    () => api.get(`/experience/v1/circuittree/${propertyId}?usage=false`),
    {
      enabled: Boolean(propertyId),
      staleTime: Infinity,
    }
  );
  return { circuitNodeTree: data, ...rest };
};

export const useGetCircuitNodeTreeWithUsage = (propertyId: string | undefined, dateRange: TimestampRange) => {
  const { data, ...rest } = useQuery<CircuitNodeWithUsage[], Error>(
    [QueryKeys.CIRCUITNODES, propertyId, "USAGE", dateRange.fromTimestamp, dateRange.toTimestamp],
    () =>
      api.get(
        `/experience/v1/circuittree/${propertyId}?usage=true&fromTimestamp=${dateRange.fromTimestamp}&toTimestamp=${dateRange.toTimestamp}`
      ),
    {
      enabled: Boolean(propertyId && dateRange.fromTimestamp && dateRange.toTimestamp),
      staleTime: Infinity,
    }
  );
  return { circuitNodeTree: data, ...rest };
};

export const useGetCircuitNodeTreesWithUsageForProperties = (
  propertyIds: string[],
  dateRange: TimestampRange,
  options?: UseQueryOptions<CircuitNodeWithUsage[], Error>
) => {
  const { data: data, ...rest } = useAggregatedQueries<CircuitNodeWithUsage[], Error>(
    propertyIds.map((propertyId) => ({
      queryKey: [QueryKeys.CIRCUITNODES, propertyId, "USAGE", dateRange.fromTimestamp, dateRange.toTimestamp],
      queryFn: async () => {
        return api.get(
          `/experience/v1/circuittree/${propertyId}?usage=true&fromTimestamp=${dateRange.fromTimestamp}&toTimestamp=${dateRange.toTimestamp}`
        );
      },
      enabled: Boolean(propertyId && dateRange.fromTimestamp && dateRange.toTimestamp),
      staleTime: Infinity,
      retry: false,
      ...options,
    }))
  );

  return { circuitTrees: data ? data : [], ...rest };
};

export const useGetCircuitNodePower = (
  circuitNodeId: string | undefined,
  dateRange: TimestampRange,
  resolution: ReadingResolution = "TOTAL",
  options: UseQueryOptions<CircuitNodeUsage, Error> = {}
) => {
  const { data, ...rest } = useQuery<CircuitNodeUsage, Error>(
    [QueryKeys.CIRCUITNODES, circuitNodeId, "POWER", dateRange.fromTimestamp, dateRange.toTimestamp],
    () =>
      api.get(
        `/property/v2/circuitNode/${circuitNodeId}/power?resolution=${resolution}&fromTimestamp=${dateRange.fromTimestamp}&toTimestamp=${dateRange.toTimestamp}`
      ),
    {
      enabled: Boolean(circuitNodeId && dateRange.fromTimestamp && dateRange.toTimestamp),
      staleTime: Infinity,
      ...options,
    }
  );
  return { circuitNodePower: data, ...rest };
};

interface CombinedCircuitNodePowerProps {
  circuitNodeIds: string[];
  tagId?: string;
  dateRange: TimestampRange;
  resolution?: ReadingResolution;
  enabled?: boolean;
}

export const useGetCombinedCircuitNodePower = ({
  circuitNodeIds,
  tagId,
  dateRange,
  resolution = "TOTAL",
  enabled = true,
}: CombinedCircuitNodePowerProps) => {
  return useAggregatedQueries<POWER_READING | POWER_READING[], Error>(
    circuitNodeIds.map((circuitNodeId) => ({
      queryKey: [
        QueryKeys.CIRCUITNODES,
        circuitNodeId,
        tagId,
        "POWER",
        dateRange.fromTimestamp,
        dateRange.toTimestamp,
        resolution,
      ],
      queryFn: async () => {
        let route = `/property/v2/circuitNode/${circuitNodeId}/power?resolution=${resolution}&fromTimestamp=${dateRange.fromTimestamp}&toTimestamp=${dateRange.toTimestamp}`;
        if (tagId) {
          route += `&tagId=${tagId}`;
        }

        const result = await api.get(route);
        if (resolution === "TOTAL") {
          return result as POWER_READING;
        }

        return result as POWER_READING[];
      },
      enabled: enabled && Boolean(circuitNodeId && dateRange.fromTimestamp && dateRange.toTimestamp),
      staleTime: Infinity,
      retry: false,
    }))
  );
};

export const useGetCircuitNodeAndSubnodes = (propertyId: string | undefined, circuitNodeId: string) => {
  const { circuitNodeTree, isLoading, isRefetching, refetch, error } = useGetCircuitNodeTree(propertyId);

  const getCircuitNodeFromTree = (
    circuitNodeTree: CircuitNode[] | undefined,
    circuitNodeId: string
  ): CircuitNode | undefined => {
    if (!circuitNodeId) return undefined;
    if (!circuitNodeTree) return undefined;
    const circuitNode = circuitNodeTree?.find((circuitNode) => circuitNode.id === circuitNodeId);
    if (circuitNode) {
      return circuitNode;
    }
    for (const circuitNode of circuitNodeTree) {
      const childCircuitNode = getCircuitNodeFromTree(circuitNode.children, circuitNodeId);
      if (childCircuitNode) {
        return childCircuitNode;
      }
    }
    return undefined;
  };

  const node = getCircuitNodeFromTree(circuitNodeTree, circuitNodeId);

  return {
    circuitNode: node,
    isLoading,
    isRefetching,
    refetch,
    error,
  };
};

export interface CreateCircuitNodeInput {
  id?: string;
  propertyId: string;
  name: string;
  type: CircuitNodeType;
  parentId: string | null;
  tagIds: string[];
}

export const useCreateCircuitNode = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  const handleCreateCircuitNode = async ({ input }: { input: CreateCircuitNodeInput }) => {
    return api.post(`property/v2/circuitNode`, input);
  };

  return useMutation(handleCreateCircuitNode, {
    onSuccess: (data, { input }) => {
      queryClient.invalidateQueries([QueryKeys.CIRCUITNODES, input.propertyId]);
      queryClient.invalidateQueries([QueryKeys.PROPERTY, input.propertyId]);
      if (onSuccess) {
        onSuccess();
      }
    },
  });
};

export const useEditCircuitNode = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  const handleEditCircuitNode = async ({ input }: { input: CreateCircuitNodeInput }) => {
    return api.put(`property/v2/circuitNode/${input.id}`, input);
  };

  return useMutation(handleEditCircuitNode, {
    onSuccess: (data, { input }) => {
      queryClient.invalidateQueries([QueryKeys.CIRCUITNODES, input.propertyId]);
      queryClient.invalidateQueries([QueryKeys.PROPERTY, input.propertyId]);
      if (onSuccess) {
        onSuccess();
      }
    },
  });
};

export const useDeleteCircuitNode = () => {
  const queryClient = useQueryClient();

  const handleDeleteCircuitNode = async ({
    input,
  }: { input: { circuitNode: CircuitNode; propertyId: string } } & MutationProps) => {
    if (input.circuitNode.circuits.filter((c) => c.device).length > 0) {
      throw new Error("You can't delete a circuit with devices attached");
    }

    for (let i = 0; i < input.circuitNode.circuits.length; i++) {
      const circuit = input.circuitNode.circuits[i];
      await api.del(`/property/v2/circuit/${circuit.id}`);
    }

    return await api.del(`/property/v2/circuitNode/${input.circuitNode.id}`);
  };

  return useMutation(handleDeleteCircuitNode, {
    onError: (error: unknown, { onError }) => {
      if (onError) onError(error);
    },
    onSuccess: (data, { input, onSuccess }) => {
      queryClient.invalidateQueries([QueryKeys.CIRCUITNODES, input.propertyId]);
      queryClient.invalidateQueries([QueryKeys.PROPERTY, input.propertyId]);

      if (onSuccess) onSuccess(data);
    },
  });
};

export const useUpdateOrganisationMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    ({ input }: { input: Organisation }) => api.put(`/customer/v1/organisation/${input.id}`, input),
    {
      onError: () => {
        toast({
          title: "Unable to update organisation",
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      },
      onSuccess: (data, { input }) => {
        toast({
          title: "Organisation Updated",
          status: "success",
          isClosable: true,
          position: "top-right",
        });
        // Invalidate and refetch
        queryClient.invalidateQueries(QueryKeys.ORGANISATION);
        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );
};

export const useCreateOrganisationMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    ({ input }: { input: Partial<Organisation> }) => api.post(`/customer/v1/organisation`, input),
    {
      onError: (error: Error) => {
        console.log(error);
        toast({
          title: "Unable to create organisation: " + error.message,
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      },
      onSuccess: (data, { input }) => {
        toast({
          title: "Organisation Created",
          status: "success",
          isClosable: true,
          position: "top-right",
        });
        // Invalidate and refetch
        queryClient.invalidateQueries(QueryKeys.ORGANISATION);
        queryClient.invalidateQueries(UserQueryKeys.USER_ORGANISATION);
        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );
};

export const useGetCustomerOrgs = (customerId: string | undefined) => {
  const { data, ...rest } = useQuery<Organisation[], Error>(
    [QueryKeys.ORGANISATION],
    () => api.get(`/customer/v1/organisation?customerId=${customerId}`),
    {
      enabled: Boolean(customerId),
      staleTime: Infinity,
    }
  );
  return { organisations: data, ...rest };
};

export interface Customer {
  id: string;
  name: string;
}

export const useGetCurrentCustomer = () => {
  const { data, ...rest } = useQuery<Customer, Error>(
    [QueryKeys.CUSTOMER, "current"],
    () => api.get(`/account/v2/customer/current`),
    {
      staleTime: Infinity,
    }
  );
  return { customer: data, ...rest };
};

export const useUpdateCustomerMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    ({ input }: { input: Customer }) => api.put(`/customer/v1/customer/${input.id}`, input),
    {
      onError: () => {
        toast({
          title: "Unable to update customer",
          status: "error",
          isClosable: true,
          position: "top-right",
        });
      },
      onSuccess: (data, { input }) => {
        toast({
          title: "Customer Updated",
          status: "success",
          isClosable: true,
          position: "top-right",
        });
        // Invalidate and refetch
        queryClient.invalidateQueries(QueryKeys.CUSTOMER);
        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );
};

export const useGetUserCustomers = () => {
  const { data: userCustomers, ...rest } = useQuery<Customer[]>(
    [QueryKeys.CUSTOMER, "all"],
    () => api.get(`/customer/v1/customer`),
    {
      staleTime: Infinity,
    }
  );

  return { userCustomers, ...rest };
};

export type InsightCategories =
  | "Usage"
  | "Maintenance"
  | "Optimisation"
  | "Trending"
  | "Phase Balancing"
  | "Energy Wastage";

export type InsightHighlightVote = "GOOD" | "BAD";

export interface InsightHighlight {
  id: string;
  propertyId: string;
  text: string;
  score: number;
  category: InsightCategories;
  dataTableString: string;
  context: {
    entityId: string;
    entityName: string;
    dateRanges: {
      startTimestamp: string;
      endTimestamp: string;
    }[];
  };
  userVote?: InsightHighlightVote;
}

export interface ExpandedInsightHighlightResult {
  expandedInsight: string;
  suggestedImprovements: string;
}

export type ModelType = "SLOW" | "MEDIUM" | "FAST";

export const useGetPropertyInsightHighlights = (propertyId: string | undefined, isEnabled = true) => {
  const { data, ...rest } = useQuery<InsightHighlight[], Error>(
    [QueryKeys.INSIGHT_HIGHLIGHTS, propertyId],
    () => api.get(`property/v2/property/${propertyId}/insighthighlights`),
    {
      enabled: Boolean(propertyId && isEnabled),
      staleTime: 60000,
      retry: false,
    }
  );

  // sort by score desc

  return {
    insightHighlights: data?.sort((a, b) => {
      return a.score > b.score ? -1 : 1;
    }),
    ...rest,
  };
};

export const useGetInsightHighlightDetails = (propertyId: string, insightHighlightId: string) => {
  const { data, ...rest } = useQuery<ExpandedInsightHighlightResult, Error>(
    [QueryKeys.INSIGHT_HIGHLIGHTS_DETAIL, insightHighlightId],
    () => api.get(`property/v2/property/${propertyId}/insighthighlights/${insightHighlightId}/detail`),
    // /:id/insighthighlights/:insightId
    // () =>
    //   new Promise((resolve) => {
    //     return resolve({
    //       expandedInsight:
    //         "The Q3 2023 energy consumption data for the building demonstrates a pronounced decline across all phases, indicating enhanced energy efficiency or reduced operational activity over these months. Specifically, the largest drop is observed in Phase 2, decreasing from 130kWh in August to 48kWh in October, a reduction of over 60%. Notably, both the maximum and minimum current measurements for all phases exhibit a downward trend, suggesting consistent load reductions or effective load management. This consistent decline across phases and current readings could imply systematic improvements in energy use or changes in occupancy patterns.",
    //       suggestedImprovements:
    //         "To build upon these positive trends, it is recommended to conduct an energy audit to identify the specific changes leading to reduced consumption. Implementation of smart energy management systems could ensure continued efficiency gains. Moreover, applying predictive maintenance on HVAC and lighting systems might extend the decreasing trend. Encouraging behavioral change among building occupants through energy awareness campaigns can also contribute to further energy savings.",
    //     });
    //   }),
    {
      enabled: Boolean(propertyId && insightHighlightId),
      staleTime: Infinity,
      retry: false,
    }
  );

  // sort by score desc

  return {
    expandedHighlight: data,
    ...rest,
  };
};

export const useGetInsightHighlightGraph = (propertyId: string, insightHighlightId: string) => {
  const { data, ...rest } = useQuery<any, Error>(
    [QueryKeys.INSIGHT_HIGHLIGHTS_CHART, insightHighlightId],
    () => api.get(`property/v2/property/${propertyId}/insighthighlights/${insightHighlightId}/chart`),
    // /:id/insighthighlights/:insightId
    // () =>
    //   new Promise((resolve) => {
    //     return resolve({
    //       expandedInsight:
    //         "The Q3 2023 energy consumption data for the building demonstrates a pronounced decline across all phases, indicating enhanced energy efficiency or reduced operational activity over these months. Specifically, the largest drop is observed in Phase 2, decreasing from 130kWh in August to 48kWh in October, a reduction of over 60%. Notably, both the maximum and minimum current measurements for all phases exhibit a downward trend, suggesting consistent load reductions or effective load management. This consistent decline across phases and current readings could imply systematic improvements in energy use or changes in occupancy patterns.",
    //       suggestedImprovements:
    //         "To build upon these positive trends, it is recommended to conduct an energy audit to identify the specific changes leading to reduced consumption. Implementation of smart energy management systems could ensure continued efficiency gains. Moreover, applying predictive maintenance on HVAC and lighting systems might extend the decreasing trend. Encouraging behavioral change among building occupants through energy awareness campaigns can also contribute to further energy savings.",
    //     });
    //   }),
    {
      enabled: Boolean(propertyId && insightHighlightId),
      staleTime: Infinity,
      retry: false,
    }
  );

  // sort by score desc

  return {
    chartData: data?.highchartsData,
    ...rest,
  };
};

export const useSubmitInsightHighlightVoteMutation = (onSuccess?: () => void, onError?: () => void) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(
    ({
      vote,
      comment,
      propertyId,
      insightHighlighId,
    }: {
      vote: InsightHighlightVote;
      comment: string;
      propertyId: string;
      insightHighlighId: string;
    }) =>
      api.post(`property/v2/property/${propertyId}/insighthighlights/${insightHighlighId}/vote`, {
        vote,
        comment,
      }),
    {
      onError: () => {
        toast({
          title: "Unable to submit vote",
          status: "error",
          isClosable: true,
          position: "top-right",
        });

        if (onError) {
          onError();
        }
      },
      onSuccess: (data, { propertyId }) => {
        toast({
          title: "Vote Submitted",
          status: "success",
          isClosable: true,
          position: "top-right",
        });
        // Invalidate and refetch
        queryClient.invalidateQueries([QueryKeys.INSIGHT_HIGHLIGHTS, propertyId]);
        if (onSuccess) {
          onSuccess();
        }
      },
    }
  );
};
